import React, {Component, Fragment} from 'react';

import {clearMessage} from "./actions/message";

import {history} from './helpers/history';
import {ToastContainer} from "react-toastify";
import Header from "./components/Header";
import Rightchat from "./components/Rightchat";
import AppRoute from "./AppRoute";
import Appfooter from "./components/Appfooter";
import {connect} from "react-redux";
import {Role} from "./helpers/role";

import 'primereact/resources/themes/mdc-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';

class App extends Component {

    constructor(props) {
        super(props);

        history.listen(() => {
            props.dispatch(clearMessage()) // clear message when changing location
        });
    }

    render() {
        return (
            <Fragment>
                <ToastContainer/>
                <Header/>
                {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                <Rightchat/>}
                <AppRoute/>
                <Appfooter/>
            </Fragment>
        );
    }
}


function mapStateToProps(state) {
    const {account} = state.auth;

    return {
        account
    };
}

export default connect(mapStateToProps)(App);

