import { combineReducers } from "redux";
import { i18nReducer } from "react-redux-i18n";
import auth from "./auth";
import message from "./message";

export default combineReducers({
  i18n: i18nReducer,
  auth,
  message
});
