import React, {Component} from "react";
import Friends from '../components/Friends';
import Contacts from '../components/Contacts';
import Group from '../components/Group';
import Events from '../components/Events';
import Load from '../components/Load';
import Profilephoto from '../components/Profilephoto';
import {Button, Modal, OverlayTrigger, Popover} from "react-bootstrap";


import PostService from "../services/PostService";
import {I18n} from "react-redux-i18n";
import {toast} from "react-toastify";
import {getProfileUrl} from "../helpers/utils";
import {connect} from "react-redux";
import AlumniTimeAgo from "../components/AlumniTimeAgo";
import AlumniReadMore from "../components/AlumniReadMore";
import {Role} from "../helpers/role";
import CommentService from "../services/CommentService";
import AlumniReadMoreComment from "../components/AlumniReadMoreComment";
import InfiniteScroll from "react-infinite-scroll-component";


class Offer extends Component {
    emptyElement = {
        id: null,
        description: '',
    };

    emptyComment = {
        id: null,
        targetIdentifier: null,
        description: '',
    };

    constructor(props) {
        super(props);
        this.pageSize = 8; //elements to load
        this.state = {
            page: 0,
            pinnedPosts: [],
            elements: [],
            hasMoreItems: true,
            loadingDialog: false,
            loadingPinned: false,
            element: this.emptyElement,
            comment: this.emptyComment,
            selectedComment: this.emptyComment,
            elementDialog: false,
            commentDialog: false,
            pinnedDialog: false,
            reportDialog: false,
            deleteElementDialog: false,
            deleteCommentDialog: false,
            commentReportDialog: false,
            elementComment: null,
            commentIdentifier: null,
            submitted: false,
            message: '' //for report only
        }
        this.elementService = new PostService();
        this.commentService = new CommentService();
        this.handleOpenNew = this.handleOpenNew.bind(this);
        this.handleHideDialog = this.handleHideDialog.bind(this);
        this.saveElement = this.saveElement.bind(this);
        this.saveComment = this.saveComment.bind(this);
        this.managePin = this.managePin.bind(this);
        this.toggleOpen = this.toggleOpen.bind(this);
        this.toggleOpenComment = this.toggleOpenComment.bind(this);
        this.deletePost = this.deletePost.bind(this);
        this.deleteComment = this.deleteComment.bind(this);
        this.report = this.report.bind(this);
        this.reportComment = this.reportComment.bind(this);
        this.handleOpenComments = this.handleOpenComments.bind(this);
        this.handleLoadMore = this.handleLoadMore.bind(this);
        this.loadFirstElements = this.loadFirstElements.bind(this);
    }

    componentDidMount() {
        this.loadFirstElements();
    }

    loadFirstElements() {
        this.setState({
            hasMoreItems: true
        });
        this.elementService.getPage(0, this.pageSize, null, null)
            .then(response => {
                //console.log('first elements ...', response)
                let hasMoreItems = true
                if (response.data.content && response.data.content.totalPageCount < this.pageSize) {
                    hasMoreItems = false
                }

                this.setState({
                    hasMoreItems,
                    elements: response.data.content.list ? response.data.content.list : [],
                    page: 1
                });
            })
            .then(() => {
                this.loadAllPinnedPosts();
            })
            .catch(error => {
            });
    }

    loadAllPinnedPosts() {
        this.elementService.getAllPinned()
            .then(response => {
                let elements = [...this.state.elements]
                let pinnedData = response.data
                let result = pinnedData.concat(elements);
                const uniqueElements = [...new Map(result.map(element => [element['id'], element])).values()]
                this.setState({
                    elements: uniqueElements
                });
            }).catch(error => {
        });
    }

    handleLoadMore() {
        this.elementService.getPage(this.state.page, this.pageSize, null, null)
            .then(response => {
                //console.log('get page data ', response)
                if (response.data.totalPageCount < this.pageSize) {
                    this.setState({
                        hasMoreItems: false
                    });
                }

                let result = this.state.elements.concat(response.data.content.list ? response.data.content.list : []);
                this.setState({
                    elements: [...new Map(result.map(element => [element['id'], element])).values()],
                    page: this.state.page + 1
                });
            }).catch(error => {
        });
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let element = {...this.state.element};
        element[`${name}`] = val;
        this.setState({element});
    }

    onInputCommentChange(e, name) {
        e.preventDefault()
        const val = (e.target && e.target.value) || '';
        let comment = {...this.state.comment};
        comment[`${name}`] = val;
        this.setState({comment});
    }

    onInputSelectedCommentChange(e, name) {
        e.preventDefault()
        const val = (e.target && e.target.value) || '';
        let selectedComment = {...this.state.selectedComment};
        selectedComment[`${name}`] = val;
        this.setState({selectedComment});
    }

    findIndexByIdentifier(list, identifier) {
        let index = -1;
        for (let i = 0; i < list.length; i++) {
            if (list[i].identifier === identifier) {
                index = i;
                break;
            }
        }
        return index;
    }

    handleOpenNew() {
        this.setState({
            element: this.emptyElement,
            elementDialog: true,
            submitted: false,
        });
    }

    handleOpenComments(event, elementIdentifier) {
        event.preventDefault();
        let comment = {...this.state.comment};
        if (elementIdentifier === comment.targetIdentifier) {
            //we have to close comment dialog
            comment = this.emptyComment;
            comment.targetIdentifier = null;
        } else {
            //there are new click
            comment = this.emptyComment;
            comment.targetIdentifier = elementIdentifier;
            this.commentService.getAll(elementIdentifier)
                .then((response) => {
                    //whe add comments to posts
                    const index = this.findIndexByIdentifier(this.state.elements, elementIdentifier);
                    let elements = [...this.state.elements];
                    let element = elements[index];
                    element.comments = response.data
                    element.commentsSize = response.data.length
                    elements[index] = element;
                    this.setState({elements});
                })
                .catch(error => {
                    this.setState({
                        loadingDialog: false,
                    });
                });
        }

        this.setState({
            comment,
            submitted: false,
        });
    }

    handleHideDialog() {
        this.setState({
            element: this.emptyElement,
            selectedComment: this.emptyComment,
            elementDialog: false,
            commentDialog: false,
            pinnedDialog: false,
            reportDialog: false,
            commentReportDialog: false,
            deleteElementDialog: false,
            deleteCommentDialog: false,
            submitted: false,
        });
    }


    managePin() {
        this.elementService.managePin(this.state.element.identifier)
            .then(response => {
                let elements = [...this.state.elements];
                elements[this.findIndexByIdentifier(this.state.elements, this.state.element.identifier)] = response.data;
                this.setState({
                    pinnedDialog: false,
                    elements: elements,
                    element: this.emptyElement,
                });
                toast.info(I18n.t('OPERATION_SUCCESSFUL'));
            }).catch(error => {
            toast.error(I18n.t(error.response.data));
        });
    }

    report() {
        let state = {submitted: true};
        if (this.state.message.trim()) {
            this.setState({
                loadingDialog: true,
            });
            this.elementService.report(this.state.element.identifier, this.state.message)
                .then((response) => {
                    let elements = [...this.state.elements];
                    elements[this.findIndexByIdentifier(this.state.elements, this.state.element.identifier)] = response.data;
                    state = {
                        ...state,
                        elements,
                        reportDialog: false,
                        element: this.emptyElement,
                        submitted: false,
                        message: '',
                        loadingDialog: false,
                    };
                    this.setState(state);
                    toast.info(I18n.t('OPERATION_SUCCESSFUL'));
                })
                .catch(error => {
                    this.setState({
                        loadingDialog: false,
                    });
                    toast.error(I18n.t(error.response.data));
                });
        }
        this.setState(state);
    }

    reportComment() {
        let state = {submitted: true};
        if (this.state.message.trim()) {
            this.setState({
                loadingDialog: true,
            });
            this.commentService.report(this.state.selectedComment.identifier, this.state.message)
                .then((response) => {
                    const index = this.findIndexByIdentifier(this.state.elements, this.state.selectedComment.targetIdentifier);
                    let elements = [...this.state.elements];
                    let element = elements[index];
                    let comments = element.comments;
                    const indexComment = this.findIndexByIdentifier(comments, this.state.selectedComment.identifier);
                    comments[indexComment] = response.data;
                    element.comments = comments
                    elements[index] = element
                    state = {
                        ...state,
                        elements,
                        commentReportDialog: false,
                        element: this.emptyElement,
                        selectedComment: this.emptyComment,
                        submitted: false,
                        message: '',
                        loadingDialog: false,
                    };
                    this.setState(state);
                    toast.info(I18n.t('OPERATION_SUCCESSFUL'));
                })
                .catch(error => {
                    this.setState({
                        loadingDialog: false,
                    });
                    toast.error(I18n.t(error.response.data));
                });
        }
        this.setState(state);
    }


    deletePost() {
        this.elementService.delete(this.state.element.id)
            .then(response => {
                let elements = this.state.elements.filter(val => val.id !== this.state.element.id);
                this.setState({
                    pinnedDialog: false,
                    elements: elements,
                    element: this.emptyElement,
                    deleteElementDialog: false,
                });
                toast.info(I18n.t('OPERATION_SUCCESSFUL'));
            }).catch(error => {
            toast.error(I18n.t(error.response.data));
        });
    }

    deleteComment() {
        this.commentService.delete(this.state.selectedComment.id)
            .then(response => {
                const index = this.findIndexByIdentifier(this.state.elements, this.state.selectedComment.targetIdentifier);
                let elements = [...this.state.elements];
                let element = elements[index];
                element.comments = element.comments.filter(val => val.id !== this.state.selectedComment.id);
                element.commentsSize = element.commentsSize - 1
                elements[index] = element;
                this.setState({
                    elements: elements,
                    selectedComment: this.emptyComment,
                    deleteCommentDialog: false,
                });
                toast.info(I18n.t('OPERATION_SUCCESSFUL'));
            }).catch(error => {
            toast.error(I18n.t(error.response.data));
        });

    }

    saveElement() {
        let state = {submitted: true};

        if (this.state.element.description.trim()) {
            let elements = [...this.state.elements];
            this.setState({
                loadingDialog: true,
            });
            if (this.state.element.id) { //may be update
                this.elementService.edit(this.state.element.id, this.state.element)
                    .then((response) => {
                        const index = this.findIndexByIdentifier(this.state.elements, this.state.element.identifier);
                        elements[index] = response.data;
                        state = {
                            ...state,
                            elements,
                            elementDialog: false,
                            element: this.emptyElement,
                            submitted: false,
                            loadingDialog: false
                        };
                        this.setState(state);
                        toast.info(I18n.t('OPERATION_SUCCESSFUL'));
                    })
                    .catch(error => {
                        this.setState({
                            loadingDialog: false,
                        });
                        toast.error(I18n.t(error.response.data));
                    });
            } else { //may be creation
                this.elementService.add(this.state.element)
                    .then((response) => {
                        elements.unshift(response.data);
                        state = {
                            ...state,
                            elements,
                            elementDialog: false,
                            element: this.emptyElement,
                            submitted: false,
                            loadingDialog: false,
                        };
                        this.setState(state);
                        toast.info(I18n.t('OPERATION_SUCCESSFUL'));
                    })
                    .catch(error => {
                        this.setState({
                            loadingDialog: false,
                        });
                        toast.error(I18n.t(error.response.data));
                    });
            }
        }
        this.setState(state);
    }

    saveComment() {
        let state = {submitted: true};

        if (this.state.comment.description.trim() || this.state.selectedComment.description.trim()) {
            //let elements = [...this.state.elements];
            this.setState({
                loadingDialog: true,
            });
            if (this.state.comment.id || this.state.selectedComment.id) { //may be update
                this.commentService.edit(this.state.selectedComment.id, this.state.selectedComment)
                    .then((response) => {
                        const index = this.findIndexByIdentifier(this.state.elements, this.state.selectedComment.targetIdentifier);
                        let elements = [...this.state.elements];
                        let element = elements[index];
                        let comments = element.comments;
                        const indexComment = this.findIndexByIdentifier(comments, this.state.selectedComment.identifier);
                        comments[indexComment] = response.data;
                        element.comments = comments
                        elements[index] = element

                        this.setState({
                            elements,
                            selectedComment: this.emptyComment,
                            commentDialog: false,
                            submitted: false,
                        });
                        toast.info(I18n.t('OPERATION_SUCCESSFUL'));
                    })
                    .catch(error => {
                        this.setState({
                            loadingDialog: false,
                        });
                        toast.error(I18n.t(error.response.data));
                    });
            } else { //may be creation
                this.commentService.add(this.state.comment)
                    .then((response) => {
                        //console.log('comment response..',response.data)
                        let comment = {...this.state.comment}
                        comment.description = '';

                        //whe add new comment to post
                        const index = this.findIndexByIdentifier(this.state.elements, this.state.comment.targetIdentifier);
                        let elements = [...this.state.elements];
                        let element = elements[index];
                        element.comments.unshift(response.data)
                        element.commentsSize = element.commentsSize + 1
                        elements[index] = element;
                        this.setState({
                            elements,
                            comment,
                            submitted: false,
                        });
                        toast.info(I18n.t('OPERATION_SUCCESSFUL'));
                    })
                    .catch(error => {
                        this.setState({
                            loadingDialog: false,
                        });
                        toast.error(I18n.t(error.response.data));
                    });
            }
        }
        this.setState(state);
    }

    toggleOpen(event, element) {
        event.preventDefault();
        if (this.state.element.id === element.id) {
            this.setState({
                element: this.emptyElement
            });
        } else {
            this.setState({
                element: element
            });
        }
    }

    toggleOpenComment(event, comment) {
        event.preventDefault();
        if (this.state.selectedComment.id === comment.id) {
            this.setState({
                selectedComment: this.emptyElement,
                commentIdentifier: null
            });
        } else {
            this.setState({
                selectedComment: comment,
                commentIdentifier: comment.id
            });
        }
    }

    handleManageLike(event, identifier) {
        event.preventDefault();
        this.elementService.manageLike(identifier)
            .then(result => {
                let elements = [...this.state.elements];
                elements[this.findIndexByIdentifier(this.state.elements, identifier)] = result.data;
                this.setState({
                    elements: elements
                });
            }).catch(error => {
        });
    }


    render() {
        const menuCommentPopover = (
            <Popover id="popover-basic">
                <div
                    className="p-2 rounded-xxl">
                    {this.props.account && this.state.selectedComment.user && this.props.account.data.id === this.state.selectedComment.user.id &&
                    <div
                        onClick={(e) => (this.setState({commentDialog: true, commentIdentifier: null}))}
                        className="card-body p-0 d-flex mt-2">
                        <i className="feather-edit-2 text-grey-600 me-3 font-xss"></i>
                        <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">{I18n.t('EDIT')}<span
                            className="d-block font-xsssss fw-600 mt-1 lh-3 text-grey-600">{I18n.t('EDIT_COMMENT_MESSAGE')}</span>
                        </h4>
                    </div>}

                    {this.props.account && this.state.selectedComment.user && (this.props.account.data.id === this.state.selectedComment.user.id || this.props.account.data.roles.includes(Role.ROLE_ADMIN)) &&
                    <div
                        onClick={(e) => (this.setState({deleteCommentDialog: true, commentIdentifier: null}))}
                        className="card-body p-0 d-flex mt-2">
                        <i className="feather-delete text-grey-600 me-3 font-xss"></i>
                        <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">{I18n.t('DELETE')}<span
                            className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-600">{I18n.t('DELETE_COMMENT_MESSAGE')}</span>
                        </h4>
                    </div>}

                    {this.props.account && this.state.selectedComment.user && this.props.account.data.id !== this.state.selectedComment.user.id &&
                    <div
                        onClick={(e) => (this.setState({
                            commentReportDialog: true,
                            submitted: false,
                            message: '',
                            commentIdentifier: null
                        }))}
                        className="card-body p-0 d-flex mt-2">
                        <i className="feather-lock text-grey-600 me-3 font-xss"></i>
                        <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">{I18n.t('REPORT')}<span
                            className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-600">{I18n.t('REPORT_COMMENT_MESSAGE')}</span>
                        </h4>
                    </div>}
                </div>
            </Popover>
        );

        return (

            <div>
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="row feed-body">
                            <div className="col-xl-8 col-xxl-9 col-lg-8">
                                <div
                                    className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
                                    <div className="card-body p-0">
                                        <button onClick={this.handleOpenNew}
                                                className="btn font-xssss fw-600 text-grey-600 card-body p-0 d-flex align-items-center">
                                            <i className="btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight"></i>
                                            {I18n.t('ADD_POST')}
                                        </button>
                                    </div>
                                </div>

                                <InfiniteScroll
                                    dataLength={this.state.elements.length}
                                    next={this.handleLoadMore}
                                    hasMore={this.state.hasMoreItems}
                                    refreshFunction={this.loadFirstElements}
                                    pullDownToRefresh
                                    pullDownToRefreshThreshold={50}
                                    pullDownToRefreshContent={
                                        <div style={{textAlign: 'center'}}
                                             className="font-xss fw-600 text-grey-600  align-items-center">&#8595; {I18n.t('PULL_DOWN_REFRESH_MESSAGE')}</div>
                                    }
                                    releaseToRefreshContent={
                                        <div style={{textAlign: 'center'}}
                                             className="font-xss fw-600 text-grey-600  align-items-center">&#8593; {I18n.t('RELEASE_REFRESH_MESSAGE')}</div>
                                    }
                                    loader={<Load/>}
                                >
                                    {this.state.elements.map(
                                        (element, index) => (
                                            <div key={element.identifier}
                                                 className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                                                <div className="card-body p-0 d-flex">
                                                    <figure className="avatar me-3"><img
                                                        src={getProfileUrl(element.user)}
                                                        alt="avatar"
                                                        className="shadow-sm rounded-circle w45"/>
                                                    </figure>
                                                    <h4 className="fw-700 text-grey-900 font-xssss mt-1"> {element.user && element.user.firstName} {element.user && element.user.lastName}
                                                        <span
                                                            className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-600">
                                                            <AlumniTimeAgo date={element.createdOn}/>
                                                        </span>
                                                    </h4>
                                                    <div className={`ms-auto pointer`} id="dropdownMenu4"
                                                         data-bs-toggle="dropdown" aria-expanded="false"
                                                         onClick={(e) => (this.toggleOpen(e, element))}><i
                                                        className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i>
                                                    </div>
                                                    <div
                                                        className={'dropdown-menu p-4 top-70 right-0 rounded-xxl border-0 shadow-lg' + (element.id === this.state.element.id ? ' show' : '')}
                                                        aria-labelledby="dropdownMenu4">

                                                        {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                                                        <div onClick={(e) => (this.setState({pinnedDialog: true}))}
                                                             className="card-body p-0 d-flex">
                                                            <i className="feather-bookmark text-grey-600 me-3 font-lg"></i>
                                                            {element.pinned ?
                                                                <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">{I18n.t('UNPIN')}<span
                                                                    className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-600">{I18n.t('UNPIN_POST_MESSAGE')}</span>
                                                                </h4> :
                                                                <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">{I18n.t('PIN')}<span
                                                                    className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-600">{I18n.t('PIN_POST_MESSAGE')}</span>
                                                                </h4>
                                                            }
                                                        </div>}

                                                        {element.user && this.props.account && this.props.account.data.id === element.user.id &&
                                                        <div onClick={(e) => (this.setState({elementDialog: true}))}
                                                             className="card-body p-0 d-flex mt-2">
                                                            <i className="feather-edit-2 text-grey-600 me-3 font-lg"></i>
                                                            <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">{I18n.t('EDIT')}<span
                                                                className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-600">{I18n.t('EDIT_POST_MESSAGE')}</span>
                                                            </h4>
                                                        </div>}

                                                        {element.user && this.props.account && (this.props.account.data.id === element.user.id || this.props.account.data.roles.includes(Role.ROLE_ADMIN)) &&
                                                        <div
                                                            onClick={(e) => (this.setState({deleteElementDialog: true}))}
                                                            className="card-body p-0 d-flex mt-2">
                                                            <i className="feather-delete text-grey-600 me-3 font-lg"></i>
                                                            <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">{I18n.t('DELETE')}
                                                                <span
                                                                    className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-600">{I18n.t('DELETE_POST_MESSAGE')}</span>
                                                            </h4>
                                                        </div>}

                                                        {element.user && this.props.account && this.props.account.data.id !== element.user.id &&
                                                        <div
                                                            onClick={(e) => (this.setState({
                                                                reportDialog: true,
                                                                submitted: false,
                                                                message: ''
                                                            }))}
                                                            className="card-body p-0 d-flex mt-2">
                                                            <i className="feather-lock text-grey-600 me-3 font-lg"></i>
                                                            <h4 className="fw-600 mb-0 text-grey-900 font-xssss mt-0 me-4 pointer">{I18n.t('REPORT')}
                                                                <span
                                                                    className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-600">{I18n.t('REPORT_POST_MESSAGE')}</span>
                                                            </h4>
                                                        </div>}
                                                    </div>

                                                </div>
                                                {element.media && element.media.contentType.startsWith('video') ?
                                                    <div
                                                        className="card-body p-0 mb-3 rounded-3 overflow-hidden uttam-die">
                                                        <a href={element.media.url} className="video-btn">
                                                            <video autoPlay loop className="float-right w-100">
                                                                <source src={element.media.url}
                                                                        type={element.media.contentType}/>
                                                            </video>
                                                        </a>
                                                    </div>
                                                    : ''}
                                                <div className="card-body p-0">
                                                    <span className="fw-500 text-grey-800 lh-26 font-xsss w-100 mb-2">
                                                        <AlumniReadMore text={element.description}/>
                                                    </span>
                                                </div>
                                                {element.media && element.media.contentType.startsWith('image') ?
                                                    <div className="card-body d-block p-0 mb-3">
                                                        <div className="row ps-2 pe-2">
                                                            <div className="col-sm-12 p-1"><img
                                                                src={element.media.url}
                                                                className="rounded-3 w-100"
                                                                alt="post"/></div>
                                                        </div>
                                                    </div>
                                                    : ''}
                                                <div className="card-body d-flex p-0">
                                                    <div
                                                        className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2"
                                                        onClick={(e) => this.handleManageLike(e, element.identifier)}>
                                                        <i className={'feather-thumbs-up text-white me-1 btn-round-xs font-xss ' + (element.likes && element.likes.includes(this.props.profile.id) ? ' bg-primary-gradiant' : ' bg-grey')}></i><span
                                                        className={'m-1 ' + (element.likes && element.likes.includes(this.props.profile.id) ? ' text-primary' : ' text-grey-600')}>j'aime</span> {element.likes ? element.likes.length : 0}
                                                    </div>

                                                    <div className='ms-auto pointer'>
                                                        <a href="#"
                                                           onClick={(e) => this.handleOpenComments(e, element.identifier)}
                                                           className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss"><i
                                                            className="feather-message-circle text-dark text-grey-900 btn-round-sm font-lg"></i><span
                                                            className="d-none-xss">{I18n.t('NUMBER_OF_COMMENT', {count: element.commentsSize})}</span></a>
                                                    </div>
                                                </div>

                                                {element.identifier === this.state.comment.targetIdentifier &&
                                                <div className="col-lg-12 position-relative">

                                                    <hr className="mt-2 mb-3"/>
                                                    <div className=" dark-bg  shadow-none theme-dark-bg"
                                                         style={{width: "100%"}}>
                                                        <div className="chat-form">
                                                            <button className="bg-grey float-left">
                                                                <img style={{
                                                                    border: 0,
                                                                    width: '40px',
                                                                    height: '40px',
                                                                    borderRadius: '20px'
                                                                }}
                                                                     src={getProfileUrl(this.props.profile)}
                                                                     alt="user avatar"/>
                                                            </button>
                                                            <div className="form-group">
                                                                <input
                                                                    style={{cursor: 'text'}}
                                                                    className="fw-500 font-xssss bg-grey"
                                                                    type="text"
                                                                    placeholder={I18n.t('COMMENT_PLACEHOLDER')}
                                                                    value={this.state.comment.description}
                                                                    onChange={(e) => this.onInputCommentChange(e, 'description')}
                                                                />
                                                            </div>
                                                            <button onClick={this.saveComment}
                                                                    className="bg-current">
                                                                <i className="ti-arrow-right text-white"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="chat-wrapper pt-0 w-100 position-relative scroll-bar bg-white theme-dark-bg"
                                                        style={{maxHeight: 'calc(100vh - 605px', height: 'unset'}}
                                                    >
                                                        <div className="chat-body">
                                                            <div className="messages-content ">

                                                                {element.comments && element.comments.map(
                                                                    (comment, index) => (
                                                                        <div key={comment.identifier}
                                                                             className="message-item"
                                                                             style={{maxWidth: 'max-content'}}>
                                                                            <div className="message-user"
                                                                                 style={{alignItems: 'start'}}>
                                                                                <figure className="avatar">
                                                                                    <img
                                                                                        src={getProfileUrl(comment.user)}
                                                                                        alt="user avatar"/>
                                                                                </figure>
                                                                                <div className="message-wrap"
                                                                                     style={{backgroundColor: '#E8EEFF'}}>
                                                                                    <div
                                                                                        className="card-body d-flex p-0">
                                                                                        <div
                                                                                            className="time text-grey-900 pr-md--2 fw-700">
                                                                                            {comment.user && comment.user.firstName} {comment.user && comment.user.lastName}
                                                                                        </div>
                                                                                        <div
                                                                                            className="time font-xssss fw-500 text-grey-600">
                                                                                            <AlumniTimeAgo
                                                                                                date={comment.createdOn}/>
                                                                                        </div>
                                                                                    </div>
                                                                                    <span
                                                                                        className="fw-500 text-grey-800 font-xsss">
                                                                                     <AlumniReadMoreComment
                                                                                         class="bg-grey"
                                                                                         text={comment.description}
                                                                                     />
                                                                                </span>
                                                                                </div>
                                                                                <OverlayTrigger trigger="click"
                                                                                                placement="bottom"
                                                                                                overlay={menuCommentPopover}
                                                                                                show={this.state.commentIdentifier === comment.id}
                                                                                >
                                                                                    <div
                                                                                        onClick={(e) => (this.toggleOpenComment(e, comment))}>
                                                                                        <i
                                                                                            className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i>
                                                                                    </div>
                                                                                </OverlayTrigger>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                )}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}

                                            </div>

                                        )
                                    )}
                                </InfiniteScroll>
                                {this.state.hasMoreItems &&
                                <div className="card-body d-block d-md-none w-100 pt-0">
                                    <a onClick={this.handleLoadMore} href="#"
                                       className="p-2 lh-28 w-100 d-block bg-grey text-grey-800 text-center font-xssss fw-700 rounded-xl">
                                        {I18n.t('READ_MORE_POSTS')}
                                    </a>
                                </div>}
                            </div>

                            <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
                                <Friends/>
                                <Contacts/>
                                <Group/>
                                <Events/>
                                <Profilephoto/>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.reportDialog} size="md" aria-labelledby="contained-modal-title-vcenter"
                       centered>
                    <Modal.Header>
                        <Modal.Title className="fw-700 text-grey-900 font-xssss" id="contained-modal-title-vcenter">
                            {I18n.t('REPORTING_POST')}
                        </Modal.Title>
                        <button onClick={this.handleHideDialog} type="button" className="close" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body style={{padding: '0px'}}>
                        <div className="card-body  position-relative">
                               <textarea
                                   name="message"
                                   className="h290 bor-0 w-100 rounded-xxl p-2  font-xssss text-grey-600 fw-500 border-light-md theme-dark-bg"
                                   cols="30"
                                   rows="10"
                                   placeholder={I18n.t('REPORT_PLACEHOLDER')}
                                   value={this.state.message}
                                   onChange={(e) => this.setState({message: e.target.value})}
                               />
                            {this.state.submitted && !this.state.message &&
                            <small className="s-invalid-feedback font-xssss">{I18n.t('FIELD_IS_REQUIRED')}</small>}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={this.report} type="button" style={{width: '100%'}}
                                className="font-xssss btn text-white btn-primary btn-sm">
                            {I18n.t('REPORT')}
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.elementDialog} size="md" aria-labelledby="contained-modal-title-vcenter"
                       centered>
                    <Modal.Header>
                        <Modal.Title className="fw-700 text-grey-900 font-xssss" id="contained-modal-title-vcenter">
                            {!!this.state.element.id ? I18n.t('EDITING_POST') : I18n.t('ADDING_POST')}
                        </Modal.Title>
                        <button onClick={this.handleHideDialog} type="button" className="close" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body style={{padding: '0px'}}>
                        <div className="card-body  position-relative">
                               <textarea
                                   name="message"
                                   className="h290 bor-0 w-100 rounded-xxl p-2  font-xsss text-grey-600 fw-500 border-light-md theme-dark-bg"
                                   cols="30"
                                   rows="10"
                                   placeholder={I18n.t('POST_DESCRIPTION_PLACEHOLDER')}
                                   value={this.state.element.description}
                                   onChange={(e) => this.onInputChange(e, 'description')}
                               />
                            {this.state.submitted && !this.state.element.description &&
                            <small className="s-invalid-feedback font-xssss">{I18n.t('FIELD_IS_REQUIRED')}</small>}
                        </div>

                        {/*<div className="card-body d-flex p-0 mt-0">
                            <a href="#photo"
                               className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i
                                className="font-md text-success feather-image me-2"></i><span
                                className="d-none-xs">Photo/Video</span></a>
                            <a href="#activity"
                               className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i
                                className="font-md text-warning feather-file me-2"></i><span
                                className="d-none-xs">Document</span></a>
                        </div>*/}
                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={this.saveElement} type="button" style={{width: '100%'}}
                                className="font-xssss btn text-white btn-primary btn-sm">
                            {!!this.state.element.id ? I18n.t('EDIT') : I18n.t('PUBLISH')}
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.pinnedDialog} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header>
                        <Modal.Title className="fw-700 text-grey-900 font-xssss">
                            {this.state.element.pinned ? I18n.t('UNPIN_POST') : I18n.t('PIN_POST')}
                        </Modal.Title>
                        <button onClick={this.handleHideDialog} type="button" className="close" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body className="text-grey-900 font-xssss">
                        {this.state.element.pinned ? I18n.t('UNPIN_POST_CONFIRMATION_MESSAGE') : I18n.t('PIN_POST_CONFIRMATION_MESSAGE')}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="light" onClick={this.handleHideDialog} className='font-xssss btn btn-sm'>
                            {I18n.t('CANCEL')}
                        </Button>
                        <Button variant="primary" onClick={this.managePin}
                                className='font-xssss btn text-white btn-primary btn-sm'>
                            {this.state.element.pinned ? I18n.t('UNPIN') : I18n.t('PIN')}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.deleteElementDialog} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header>
                        <Modal.Title className="fw-700 text-grey-900 font-xssss">
                            {I18n.t('DELETE_POST')}
                        </Modal.Title>
                        <button onClick={this.handleHideDialog} type="button" className="close" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body className="text-grey-900 font-xssss">
                        {I18n.t('DELETE_POST_CONFIRMATION_MESSAGE')}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="light" onClick={this.handleHideDialog} className='font-xssss btn btn-sm'>
                            {I18n.t('CANCEL')}
                        </Button>
                        <Button variant="primary" onClick={this.deletePost}
                                className='font-xssss btn text-white btn-primary btn-sm'>
                            {I18n.t('DELETE')}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.deleteCommentDialog} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header>
                        <Modal.Title className="fw-700 text-grey-900 font-xssss">
                            {I18n.t('DELETING_COMMENT')}
                        </Modal.Title>
                        <button onClick={this.handleHideDialog} type="button" className="close" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body className="text-grey-900 font-xssss">
                        {I18n.t('DELETE_COMMENT_CONFIRMATION_MESSAGE')}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="light" onClick={this.handleHideDialog} className='font-xssss btn btn-sm'>
                            {I18n.t('CANCEL')}
                        </Button>
                        <Button variant="primary" onClick={this.deleteComment}
                                className='font-xssss btn text-white btn-primary btn-sm'>
                            {I18n.t('DELETE')}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.commentReportDialog} size="md" aria-labelledby="contained-modal-title-vcenter"
                       centered>
                    <Modal.Header>
                        <Modal.Title className="fw-700 text-grey-900 font-xssss" id="contained-modal-title-vcenter">
                            {I18n.t('REPORTING_COMMENT')}
                        </Modal.Title>
                        <button onClick={this.handleHideDialog} type="button" className="close" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body style={{padding: '0px'}}>
                        <div className="card-body  position-relative">
                               <textarea
                                   name="message"
                                   className="h290 bor-0 w-100 rounded-xxl p-2  font-xssss text-grey-600 fw-500 border-light-md theme-dark-bg"
                                   cols="30"
                                   rows="10"
                                   placeholder={I18n.t('REPORT_PLACEHOLDER')}
                                   value={this.state.message}
                                   onChange={(e) => this.setState({message: e.target.value})}
                               />
                            {this.state.submitted && !this.state.message &&
                            <small className="s-invalid-feedback font-xssss">{I18n.t('FIELD_IS_REQUIRED')}</small>}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={this.reportComment} type="button" style={{width: '100%'}}
                                className="font-xssss btn text-white btn-primary btn-sm">
                            {I18n.t('REPORT')}
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.commentDialog} size="md" aria-labelledby="contained-modal-title-vcenter"
                       centered>
                    <Modal.Header>
                        <Modal.Title className="fw-700 text-grey-900 font-xssss" id="contained-modal-title-vcenter">
                            {I18n.t('EDITING_COMMENT')}
                        </Modal.Title>
                        <button onClick={this.handleHideDialog} type="button" className="close" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body style={{padding: '0px'}}>
                        <div className="card-body  position-relative">
                               <textarea
                                   name="message"
                                   className="h290 bor-0 w-100 rounded-xxl p-2  font-xssss text-grey-600 fw-500 border-light-md theme-dark-bg"
                                   cols="30"
                                   rows="10"
                                   placeholder={I18n.t('COMMENT_DESCRIPTION_PLACEHOLDER')}
                                   value={this.state.selectedComment.description}
                                   onChange={(e) => this.onInputSelectedCommentChange(e, 'description')}
                               />
                            {this.state.submitted && !this.state.selectedComment.description &&
                            <small className="s-invalid-feedback font-xssss">{I18n.t('FIELD_IS_REQUIRED')}</small>}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={this.saveComment} type="button" style={{width: '100%'}}
                                className="font-xssss btn text-white btn-primary btn-sm">
                            {I18n.t('EDIT')}
                        </button>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }
}

function mapStateToProps(state) {
    const {profile, user} = state.auth;

    return {
        profile,
        user
    };
}

export default connect(mapStateToProps)(Offer);
