import React, {Component, Fragment} from "react";
import {I18n} from "react-redux-i18n";
import {Constant} from "../helpers/const";
import {login} from "../actions/auth";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {Alert} from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

const recaptchaRef = React.createRef();

class Login extends Component {

    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onRecaptchaChange = this.onRecaptchaChange.bind(this);

        this.state = {
            showError: false,
            errorMessage: "",
            username: "",
            password: "",
            loading: false,
            recaptcha: false,
            recaptchaResponse: null,
        };
    }

    showResponse(response) {
        if (response) {
            this.setState({
                recaptchaResponse: response.response,
            });
        }
    }

    onChangeUsername(e) {
        this.setState({
            username: e.target.value,
        });
    }

    onChangePassword(e) {
        this.setState({
            password: e.target.value,
        });
    }

    handleLogin(e) {
        e.preventDefault();
        if (this.state.recaptcha === true && this.state.recaptchaResponse === null) {
            this.setState({
                showError: true,
                errorMessage: Constant.RECAPTCHA_NOT_SET
            });
            return
        }

        this.setState({
            loading: true,
        });
        const {dispatch, history} = this.props;
        dispatch(login(this.state.username, this.state.password, this.state.recaptchaResponse))
            .then((objectLogin) => {
                if (objectLogin.valid) {
                    if (objectLogin.messages[0].message === "ACTIVATION_CODE_SUCCESS") {
                        history.push(`/password/${this.state.username}/${this.state.password}`)
                    } else {
                        //to refresh  session data
                        window.location.reload();
                    }

                } else {
                    this.setState({
                        showError: true,
                        errorMessage: objectLogin.messages[0].message
                    });
                    this.setState({
                        loading: false,
                    });
                }
            })
            .catch(() => {
                if (this.state.recaptcha) {
                    recaptchaRef.current && recaptchaRef.current.reset()
                    this.setState({
                        recaptchaResponse: null
                    });
                }

                this.setState({
                    loading: false,
                });

                if (this.props.message === "REQUIRES_CAPTCHA_ERROR") {
                    this.setState({
                        recaptcha: true,
                    });
                }

                let errorMessage = this.props.message;
                if (errorMessage === 'USER_STATUS_PENDING_PASSWORD_ACTIVATION') {
                    errorMessage = 'USER_STATUS_PENDING_PASSWORD_ACTIVATION_MESSAGE'
                }


                this.setState({
                    showError: true,
                    errorMessage: errorMessage
                });

            });
    }

    onRecaptchaChange(value) {
        if (value) {
            this.setState({
                recaptchaResponse: value,
            });
        }
    }


    render() {
        const {isLoggedIn} = this.props;
        if (isLoggedIn) {
            return <Redirect to="/"/>;
        }
        return (
            <Fragment>
                <div className="main-wrap">
                    <div className="nav-header bg-transparent shadow-none border-0">
                        <div className="nav-top w-100">
                            <a href="/"><img src='assets/images/logo.png'
                                             className="text-success display1-size me-2 ms-0"></img><span
                                className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">Alumni ENSPM </span>
                            </a>
                            <button className="nav-menu me-0 ms-auto"></button>

                            <a href="/login"
                               className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w200 text-center lh-20 rounded-xl">{I18n.t('LOGIN')}</a>
                            <a href="/register"
                               className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w200 text-center lh-20 rounded-xl">{I18n.t('REGISTER')}</a>
                        </div>
                    </div>
                    <div className="row" style={{overflow: "scroll"}}>
                        <div className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
                             style={{backgroundImage: `url('assets/images/login-background.jpg')`}}></div>
                        <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3">
                            <div className="card shadow-none border-0 ms-auto me-auto login-card">
                                <div className="card-body rounded-0 text-left alumni-login-form">
                                    <div className="fw-700 ">{I18n.t('SIGN_IN')}</div>
                                    <form onSubmit={this.handleLogin}>

                                        <Alert className="font-xsss fw-600" show={this.state.showError}
                                               variant='danger'>
                                            {I18n.t(this.state.errorMessage)}
                                        </Alert>

                                        <div className="form-group icon-input mb-3">
                                            <i className="font-sm ti-email text-grey-500 pe-0"></i>
                                            <input type="Email"
                                                   required
                                                   value={this.state.username}
                                                   onChange={this.onChangeUsername}
                                                   className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                                   placeholder={I18n.t('EMAIL_MESSAGE')}/>
                                        </div>
                                        <div className="form-group icon-input mb-3">
                                            <input type="Password"
                                                   required
                                                   value={this.state.password}
                                                   onChange={this.onChangePassword}
                                                   className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                                                   placeholder={I18n.t('PASSWORD_MESSAGE')}/>
                                            <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                        </div>
                                        {this.state.recaptcha && <div className="form-group icon-input mb-1">
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                sitekey={Constant.RECAPTCHA_SITE_KEY}
                                                onChange={this.onRecaptchaChange}
                                            />
                                        </div>}

                                        <div className="form-check text-left mb-3">
                                            <input type="checkbox" className="form-check-input mt-2"
                                                   id="exampleCheck5"/>
                                            <label className="form-check-label font-xsss text-grey-800">
                                                {I18n.t('REMEMBER_ME')}
                                            </label>
                                            <a href="/forgotpasswordrequest"
                                               className="fw-600 font-xsss text-grey-700 mt-1 float-right">
                                                {I18n.t('FORGOT_PASSWORD_MESSAGE')}
                                            </a>
                                        </div>
                                        <div className="form-group mb-1">
                                            <button type='submit'
                                                    className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 "
                                                    disabled={this.state.loading}
                                            >
                                                {this.state.loading &&
                                                <span className="spinner-border spinner-border-sm "
                                                      style={{marginRight: '5px'}} aria-hidden="true"></span>}
                                                {I18n.t('LOGIN')}
                                            </button>
                                        </div>
                                    </form>
                                    <div className="col-sm-12 p-0 text-left">
                                        <h6 className="text-grey-800 font-xsss fw-500 mt-0 mb-0 lh-32">
                                            {I18n.t('DONT_HAVE_ACCOUNT_MESSAGE')}
                                            <a href="/register" className="fw-700 ms-1">{I18n.t('REGISTER')}</a>
                                        </h6>
                                        <h6 className="text-grey-800 font-xsss fw-500 mt-0 mb-0 lh-32">
                                            Vous avez des questions ? écrivez nous à
                                        </h6>
                                        <h6 className="text-grey-800 font-xsss fw-500 mt-0 mb-0 lh-32">
                                            <a href="mailto:contact.alumni@enspm.univ-maroua.cm">contact.alumni@enspm.univ-maroua.cm</a>
                                        </h6>
                                        <h6 className="text-grey-800 font-xsss fw-500 mt-0 mb-0 lh-32">
                                            ou dans <a href="https://t.me/+_uswUqQ7ue43NDI8">ce groupe telegram</a>
                                        </h6>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </Fragment>
        );
    }
}


function mapStateToProps(state) {
    const {isLoggedIn} = state.auth;
    const {message} = state.message;
    return {
        isLoggedIn,
        message
    };
}

export default connect(mapStateToProps)(Login);
