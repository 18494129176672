import React from "react";

const Translations = {
    en: {
        LOGIN_BAD_CREDENTIALS: "Bad credentials",
        home: "Home",
        RECAPTCHA_NOT_SET: "Recaptcha is required",
        REQUIRES_CAPTCHA_ERROR: "Bad credentials",
        CONNEXION_ERROR: 'Connexion error',
        USERNAME: "Username",
        PASSWORD: "Password",
        SIGN_IN: "Sign in",
        FORGOT_PASSWORD: "Forgot password",
        LOGIN_ACCOUNT_NOT_FOUND: "Bad credentials",
        LOGOUT: "Logout",
        USERNAME_IS_REQUIRED: "Username is required",
        NAME: "Name",
        NAME_IS_REQUIRED: "Name is required",
        FIRSTNAME: "Firstname",
        MOBILEPHONE: "Mobile phone",
        MOBILEPHONE_IS_REQUIRED: "Mobile phone is required",
        SEX: "Sex",
        GENDER_FEMALE: "Female",
        GENDER_MALE: "Male",
        PAYMENT_METHOD: "Payment method",
        PAYMENT_METHOD_IS_REQUIRED: "Payment method is required",
        CHOOSE_VALUE: "Choose a value",
        CANCEL: "Cancel",
        SAVE: "Save",
        DUPLICATED_USERNAME: "Username is already taken",
        FULLNAME: "Full name",
        USER_STATUS_INACTIVE: "Inactive",
        USER_STATUS_ACTIVE: "Active",
        USER_STATUS_PENDING_ACTIVATION: "Pending activation",
        ADD: "Add",
        INVALID_EMAIL: "Invalid email",
        USER_EXISTS: "Username is already taken",
        ACTIVATION_CODE_ERROR: "Invalid activation code",
        PASSWORD_OR_CODE: "Password or code",
        CONTINUE: "Continue",
        PASSWORD_REPEAT: "Repeat password",
        SET_PASSWORD_ERROR: "Setting password",
        ENTER_PASSWORD: "Please enter a password",
        WEAK: "Weak",
        MEDIUM: "Medium",
        STRONG: "Strong",
        PASSWORD_MISMATCH: "The two passwords are not the same",
        START_DATE: "Start date",
        END_DATE: "End date",
        SEARCH: "Search",
        EMAIL: "Email",
        LOCALISATION: "Localisation",
        EMAIL_IS_REQUIRED: "Email adress is required",
        LOCALISATION_IS_REQUIRED: "Localisation is required",
        DUPLICATED_EMAIL: "Email already used",
        FEMALE: "Female",
        MALE: "Male",
        YES: "Yes",
        NO: "No",
        INVALID_PHONE_NUMBER: "The phone number is not valid",
        DISTRIBUTED: "Distributed",
        SENT: "Sent",
        NOT_SENT: "Not sent",
        DELIVERED: "Delivered",
        READ: "Read",
        OPENED: "Opened",
        REJECTED: "Rejected",
        CLICKED: "Clicked",
        FAIL: "Fail",
        FAVORITES: "Favorites",
        DASHBOARD_STATS: "Statistics",
        DASHBOARD_ANALYTICS: "Analytics",
        OTHERS: "Others",
        DEPARTMENTS: "Departments",
        SIGN_IN_MESSAGE: "Please sign in to connect to application",
        ADMINISTRATOR: "Administrator",
        SETTINGS: "Settings",
        dashboard: "Dashboard",
        analytics: "Analytics",
        departments: "Departments",
        HOME: "Home",
        LOGIN: "Login",
        WELCOME: "Welcome",
        EDIT: "Edit",
        ASCENDING: "Ascending",
        ACRONYM: "Acronym",
        DESCENDING: "Descending",
        UPDATE: "Update",
        UPDATE_SUCCESSFUL: "Update was successful",
        ADD_SUCCESSFUL: "Add was successful",
        DELETION: "Deletion",
        DELETION_SUCCESSFUL: "Deletion was successful",
        SEARCH_NOT_IMPLEMENTED: "Search is not implemented",
        SEARCH_DATA: "Search ...",
        SELECT_ORDER: "Select order",
        ADD_DATA: "Add",
        DELETE_DATA: "Delete",
        IMPORT_DATA: "Import",
        EXPORT_DATA: "Export",
        EDIT_DATA: "Edit",
        DUPLICATED_ACRONYM: "Acronym is already used",
        PAGE_REPORT_TEMPLATE: "Showing {first} to {last} of {totalRecords} elements",
        DETAILS: "Details",
        ACRONYM_IS_REQUIRED: "Acronym is required",
        DESCRIPTION: "Description",
        CONFIRMATION: "Confirmation",
        DELETE_ONE_WARNING: "Are you sure you want to delete <b>%{name}</b>?",
        DELETE_MANY_WARNING: "Are you sure you want to delete these <b>%{count}</b> elements?",
        DELETE_MANY_WARNING_1: "Are you sure you want to delete this element?",
        SECTIONS: "Sections",
        sections: "Sections",
        DEPARTMENT_NAME: "Department name",
        DEPARTMENT_IS_REQUIRED: "Department is required",
        DEPARTMENT: "Department",
        DEPARTMENT_ACRONYM: "Department acronym",
        ADD_POST: "Post something ...",
        ADDING_POST: "Adding a publication",
        EDITING_POST: "Editing a publication",
        EDITING_COMMENT: "Editing a comment",
        POST_DESCRIPTION_PLACEHOLDER: "what do you want to publish?",
        PUBLISH: "Publish",
        FIELD_IS_REQUIRED: "This field is required.",
        PIN: "Pin",
        UNPIN: "Unpin",
        PIN_POST_MESSAGE: "Pin a post",
        UNPIN_POST_MESSAGE: "Unpin a post",
        EDIT_POST_MESSAGE: "Edit a post",
        DELETE: "Delete",
        DELETE_POST_MESSAGE: "Delete a post",
        REPORT: "Report",
        REPORT_POST_MESSAGE: "Report a post",
        READ_MORE_TEXT: "Read more",
        UNPIN_POST: "Unpin a post",
        PIN_POST: "Pin a post",
        DELETE_COMMENT_CONFIRMATION_MESSAGE: "Are you sure you want to delete this comment ?",
        DELETING_COMMENT: "Delete a comment",
        UNPIN_POST_CONFIRMATION_MESSAGE: "Are you sure you want to unpin this post ?",
        PIN_POST_CONFIRMATION_MESSAGE: "Are you sure you want to pin this post ?",
        DELETE_POST: "Delete a post",
        DELETE_POST_CONFIRMATION_MESSAGE: "Are you sure you want to delete this post ?",
        REPORTING_POST: "Report a post",
        REPORTING_COMMENT: "Report a comment",
        REPORT_PLACEHOLDER: "what do you want to report?",
        OPERATION_SUCCESSFUL: "Successful operation",
        POST_ALREADY_REPORTED: "Post already reported",
        COMMENT_ALREADY_REPORTED: "Comment already reported",
        NUMBER_OF_COMMENT: "%{count} comments",
        NUMBER_OF_COMMENT_0: "0 comment",
        NUMBER_OF_COMMENT_1: "1 comment",
        NUMBER_OF_CONTRIBUTION: "%{count} participations",
        NUMBER_OF_CONTRIBUTION_0: "0 participation",
        NUMBER_OF_CONTRIBUTION_1: "1 participation",
        COMMENT_PLACEHOLDER: "leave a comment",
        EDIT_COMMENT_MESSAGE: "Editing a comment",
        DELETE_COMMENT_MESSAGE: "Delete a comment",
        REPORT_COMMENT_MESSAGE: "Report a comment",
        READ_MORE_POSTS: "Read more posts",
        PULL_DOWN_REFRESH_MESSAGE: "Pull down to refresh",
        RELEASE_REFRESH_MESSAGE: "Release to refresh",
        REGISTER: "Register",
        EMAIL_MESSAGE: "Your Email Address",
        PASSWORD_MESSAGE: "Your password",
        REMEMBER_ME: "Remember me",
        FORGOT_PASSWORD_MESSAGE: "Forgot password ?",
        DONT_HAVE_ACCOUNT_MESSAGE: "Dont have account ?",
        USERNAME_PLACEHOLDER: "Your username. ex: sali",
        YOU_ARE: "Your are",
        GRADUATE_ENGINEER: "Graduate engineer",
        TEACHER: "Teacher",
        YOUR_DEPARTMENT: "Your department",
        CONTACT_PLACEHOLDER: "Your phone contact",
        EMAIL_PLACEHOLDER: "Your email",
        PASSWORD_PLACEHOLDER: "Your password",
        NEW_PASSWORD_PLACEHOLDER: "Your new password",
        PASSWORD_REPEAT_PLACEHOLDER: "Repeat your password",
        LASTNAME: "Last name",
        USERNAME_ALREADY_ASSIGNED: "Username already assigned",
        EMAIL_ALREADY_ASSIGNED: "Email already assigned",
        ALREADY_HAVE_ACCOUNT: "Already have account",
        ACCEPT_TERM_AND_CONDITIONS: "Accept Term and Conditions of Alumni",
        PASSWORDS_MUST_BE_IDENTICAL: "Passwords must be identical",
        CHANGE_PASSWORD: " Change Password",
        FORGOT_PASSWORD_EMAIL_SENT: "if the mail <b>%{email}</b> exists, you will receive the link to change the password in your mailbox.",
        KEY_EXPIRED_ERROR: "The key has been expired. Please renew your request.",
        INVALID_KEY_ERROR: "The key is not valid anymore",
        USER_STATUS_PENDING_PASSWORD_ACTIVATION_MESSAGE: "You have requested to change the password. Please click on the link you received by email.",
        INVALID_RESET_PASSWORD_LINK: "This link has expired or is no longer valid.",
        SEARCH_A_POST: "Search a post...",
        POSTS: "Posts",
        OFFERS: "Offers",
        PROJECTS: "Projects",
        MEMBERS: "Members",
        PROFILE: "Profile",
        MORE_PAGES: "More pages",
        EMAILS: "Emails",
        EVENTS: "Events",
        ACCOUNT: "Account",
        ANALYTICS: "Analytics",
        LATEST_REGISTERED: "Latest registered",
        SEE_ALL: "See all",
        ADD_PROJECT: "Add a project",
        UNPIN_PROJECT_MESSAGE: "Unpin a project",
        PIN_PROJECT_MESSAGE: "Pin a project",
        EDIT_PROJECT_MESSAGE: "Edit a project",
        READ_MORE_PROJECTS: "Read more projects",
        UNPIN_PROJECT: "Unpin a project",
        PIN_PROJECT: "Pin a project",
        DELETE_PROJECT_MESSAGE: "Delete a project",
        UNPIN_PROJECT_CONFIRMATION_MESSAGE: "Are you sure you want to unpin this project ?",
        PIN_PROJECT_CONFIRMATION_MESSAGE: "Are you sure you want to pin this project ?",
        DELETE_PROJECT_CONFIRMATION_MESSAGE: "Are you sure you want to delete this project ?",
        EDITING_PROJECT: "Editing a project",
        ADDING_PROJECT: "Adding a project",
        DELETE_PROJECT: "Delete a project",
        TITLE: "Title",
        COST: "Cost",
        CHANGE_FILES: "Change files",
        CHOOSE_FILES: "Choose files",
        INVALID_AMOUNT: "Invalid amount",
        UPLOAD: "Upload",
        CHANGE_REPORTS: "Change reports",
        CHOOSE_REPORTS: "Choose reports",
        END_DATE_BEFORE_START_DATE: "End date cannot be before start date",
        INVALID_DATE: "Invalid date",
        NUMBER_OF_DAYS_LEFT: "%{count} Days left",
        NUMBER_OF_DAYS_LEFT_0: "0 Day left",
        NUMBER_OF_DAYS_LEFT_1: "1 Day left",
        NUMBER_OF_DAYS_PAST: "%{count} Days ago",
        NUMBER_OF_DAYS_PAST_0: "0 Day ago",
        NUMBER_OF_DAYS_PAST_1: "1 Day ago",
        CONTRIBUTE: "Contribute",
        PAYMENT_SUCCESSFUL: "Your contribution has been made successfully",
        PAYMENT_ERROR: "Your contribution could not be made. Check your balance or payment limit",
        AMOUNT_PLACEHOLDER: "Enter amount",
        AMOUNT: "Amount",
        OPTIONAL_MESSAGE: "A small message ? (optional)",
        PROCEEDING_CONTRIBUTION: "We proceed to your contribution ...",
        CONTRIBUTE_BY_BANK_CARD: "Contribute by bank card",
        FULL_NAME: "Full name",
    },
    fr: {
        ADD_PROJECT: "Ajouter un projet",
        UNPIN_PROJECT_MESSAGE: "Détacher un projet de la une",
        PIN_PROJECT_MESSAGE: "Épingler un projet de la une",
        EDIT_PROJECT_MESSAGE: "Modifier un projet",
        READ_MORE_PROJECTS: "Afficher plus de projets",
        UNPIN_PROJECT: "Détacher un projet de la une",
        PIN_PROJECT: "Épingler un projet à la une",
        DELETE_PROJECT_MESSAGE: "Supprimer un projet",
        UNPIN_PROJECT_CONFIRMATION_MESSAGE: "Voulez-vous vraiment détacher ce projet de la une ?",
        PIN_PROJECT_CONFIRMATION_MESSAGE: "Voulez-vous vraiment épingler ce projet à la une ?",
        DELETE_PROJECT_CONFIRMATION_MESSAGE: "Voulez-vous vraiment supprimer ce projet ?",
        EDITING_PROJECT: "Modification d'un projet",
        ADDING_PROJECT: "Ajout d'un projet",
        DELETE_PROJECT: "Suppression d'un projet",
        LOGIN_BAD_CREDENTIALS: "Paramètres de connexion invalides",
        RECAPTCHA_NOT_SET: "Veuillez entrer le Recaptcha valide",
        REQUIRES_CAPTCHA_ERROR: "Paramètres de connexion invalides",
        CONNEXION_ERROR: 'Erreur de connexion',
        USERNAME: "Nom d'utilisateur",
        PASSWORD: "Mot de passe",
        SIGN_IN: "Se connecter",
        FORGOT_PASSWORD: "Mot de passe oublié",
        LOGIN_ACCOUNT_NOT_FOUND: "Paramètres de connexion invalides",
        LOGOUT: "Se deconnecter",
        USERNAME_IS_REQUIRED: "Le nom d'utilisateur est obligatoire",
        NAME: "Nom",
        NAME_IS_REQUIRED: "Le nom est obligatoire",
        FIRSTNAME: "Prénom",
        MOBILEPHONE: "Numéro de téléphone",
        MOBILEPHONE_IS_REQUIRED: "Le numéro de téléphone est obligatoire",
        SEX: "Sexe",
        GENDER_FEMALE: "Féminin",
        GENDER_MALE: "Masculin",
        PAYMENT_METHOD: "Méthode de paiement",
        PAYMENT_METHOD_IS_REQUIRED: "Méthode de paiement est obligatoire",
        CHOOSE_VALUE: "Choisir une valeur",
        CANCEL: "Annuler",
        SAVE: "Enregistrer",
        DUPLICATED_USERNAME: "Ce nom d'utilisateur est deja enregistré",
        LEVEL: "Niveau",
        LEVEL_IS_REQUIRED: "Le niveau est obligatoire",
        CLIENT_PAYMENT: "Paie d'un client",
        FULLNAME: "Nom complet",
        LAST_PAYMENT_DATE: "Date du dernier payment",
        USER_STATUS_INACTIVE: "Inactif",
        USER_STATUS_ACTIVE: "Actif",
        USER_STATUS_PENDING_ACTIVATION: "En attente d'activation",
        ADD: "Ajout",
        USER_REGISTRATION: "Ajouter un utilisateur",
        USER_REGISTRATION_SUCCESS: "L'utilisateur a été crée avec succès",
        ADD_USER: "Ajout d'un utilisateur",
        INVALID_EMAIL: "Email invalide",
        USER_EXISTS: "Ce nom d'utilisateur existe deja",
        ACTIVATION_CODE_ERROR: "Code d'activation incorrect",
        PASSWORD_OR_CODE: "Mot de passe ou code",
        CONTINUE: "Continuer",
        PASSWORD_REPEAT: "Repeter le mot de passe",
        SET_PASSWORD_ERROR: "Config. du mot de passe",
        ENTER_PASSWORD: "Entrer le mot de passe svp",
        WEAK: "Faible",
        MEDIUM: "Moyen",
        STRONG: "Fort",
        PASSWORD_MISMATCH: "Les deux mots de passe ne sont pas identiques",
        START_DATE: "Date de début",
        END_DATE: "Date de fin",
        SEARCH: "Rechercher",
        REASON_IS_REQUIRED: "La raison est obligatoire",
        LOGIN_USER_INACTIVE: "Votre compte est inactif",
        CODE: "Code",
        MESURE: "Mesure",
        QUANTITY: "Quantité",
        EMAIL: "Email",
        LOCALISATION: "Localisation",
        EMAIL_IS_REQUIRED: "L'adresse email est obligatoire",
        LOCALISATION_IS_REQUIRED: "La localisation est obligatoire",
        SINGLE: "Solo",
        COUPLE: "Couple",
        FEMALE: "Féminin",
        MALE: "Masculin",
        YES: "Oui",
        NO: "Non",
        INVALID_PHONE_NUMBER: "Le numéro de téléphone n'est pas correct",
        DISTRIBUTED: "En cours",
        SENT: "Envoyé",
        NOT_SENT: "Pas envoyé",
        DELIVERED: "Reçu",
        READ: "Lu",
        OPENED: "Ouvert",
        REJECTED: "Rejeté",
        CLICKED: "A clicqué",
        FAIL: "Echoué",
        FAVORITES: "Favoris",
        DASHBOARD_STATS: "Statistiques",
        DASHBOARD_ANALYTICS: "Métriques",
        OTHERS: "Autres",
        DEPARTMENTS: "Départements",
        SIGN_IN_MESSAGE: "Veillez renseigner des identifiants valides pour vous connecter",
        ADMINISTRATOR: "Administrateur",
        SETTINGS: "Paramètres",
        home: "Accueil",
        dashboard: "Accueil",
        analytics: "Métriques",
        departments: "Départements",
        HOME: "Accueil",
        LOGIN: "Connexion",
        WELCOME: "Bienvenue",
        EDIT: "Modifier",
        ASCENDING: "Croissant",
        ACRONYM: "Sigle",
        DESCENDING: "Décroissant",
        UPDATE: "Mise à jour",
        UPDATE_SUCCESSFUL: "Mise à jour réalisée avec succès",
        ADD_SUCCESSFUL: "Ajout réalisée avec succès",
        DELETION: "Suppression",
        DELETION_SUCCESSFUL: "Suppression réalisée avec succès",
        SEARCH_NOT_IMPLEMENTED: "La recherche n'est pas implémentée",
        SEARCH_DATA: "Rechercher ...",
        SELECT_ORDER: "Choisir l'ordre",
        ADD_DATA: "Ajouter",
        DELETE_DATA: "Supprimer",
        IMPORT_DATA: "Importer",
        EXPORT_DATA: "Exporter",
        EDIT_DATA: "Modifier",
        PAGE_REPORT_TEMPLATE: "Affichage de {first} à {last} de {totalRecords} éléments",
        DETAILS: "Détails",
        ACRONYM_IS_REQUIRED: "Le sigle est obligatoire",
        DESCRIPTION: "Description",
        CONFIRMATION: "Confirmation",
        DUPLICATED_ACRONYM: "Le sigle est déja utilisé",
        DELETE_ONE_WARNING: "Etes-vous sûr que vous voulez supprimer <b>%{name}</b>?",
        DELETE_MANY_WARNING: "Etes-vous sûr que vous voulez supprimer ces <b>%{count}</b> éléments?",
        DELETE_MANY_WARNING_1: "Etes-vous sûr que vous voulez supprimer cet élément?",
        SECTIONS: "Sections",
        sections: "Sections",
        DEPARTMENT_NAME: "Nom du département",
        DEPARTMENT_IS_REQUIRED: "Le département est obligatoire",
        DEPARTMENT: "Département",
        DEPARTMENT_ACRONYM: "Sigle du département",
        ADD_POST: "Publier quelque chose ...",
        ADDING_POST: "Ajout d'une publication",
        EDITING_POST: "Modification d'une publication",
        EDITING_COMMENT: "Modification d'un commentaire",
        POST_DESCRIPTION_PLACEHOLDER: "que veut tu publier ?",
        PUBLISH: "Publier",
        FIELD_IS_REQUIRED: "Ce champ est obligatoire.",
        PIN: "Épingler",
        UNPIN: "Détacher",
        PIN_POST_MESSAGE: "Épingler une publication de la une",
        UNPIN_POST_MESSAGE: "Détacher une publication de la une",
        EDIT_POST_MESSAGE: "Modifier une publication",
        DELETE: "Supprimer",
        DELETE_POST_MESSAGE: "Supprimer une publication",
        REPORT: "Signaler",
        REPORT_POST_MESSAGE: "Signaler une publication",
        EDIT_COMMENT_MESSAGE: "Modifier un commentaire",
        DELETE_COMMENT_MESSAGE: "Supprimer un commentaire",
        REPORT_COMMENT_MESSAGE: "Signaler un commentaire",
        READ_MORE_TEXT: "En savoir plus",
        UNPIN_POST: "Détacher une publication de la une",
        PIN_POST: "Épingler une publication à la une",
        UNPIN_POST_CONFIRMATION_MESSAGE: "Voulez-vous vraiment détacher cette publication de la une ?",
        DELETE_COMMENT_CONFIRMATION_MESSAGE: "Voulez-vous vraiment supprimer ce commentaire ?",
        DELETING_COMMENT: "Suppression d'un commentaire",
        PIN_POST_CONFIRMATION_MESSAGE: "Voulez-vous vraiment épingler cette publication à la une ?",
        DELETE_POST: "Suppression d'une publication",
        DELETE_POST_CONFIRMATION_MESSAGE: "Voulez-vous vraiment supprimer cette publication ?",
        REPORTING_POST: "Signaler une publication",
        REPORTING_COMMENT: "Signaler un commentaire",
        REPORT_PLACEHOLDER: "que veut tu signaler ?",
        OPERATION_SUCCESSFUL: "Opération effectuée avec succès",
        POST_ALREADY_REPORTED: "Vous avez déjà signalé cette publication",
        COMMENT_ALREADY_REPORTED: "Vous avez déjà signalé ce commentaire",
        NUMBER_OF_COMMENT: "%{count} commentaires",
        NUMBER_OF_COMMENT_0: "0 commentaire",
        NUMBER_OF_COMMENT_1: "1 commentaire",
        COMMENT_PLACEHOLDER: "laisser un commentaire",
        READ_MORE_POSTS: "Afficher plus de publications",
        PULL_DOWN_REFRESH_MESSAGE: "Tirez vers le bas pour actualiser",
        RELEASE_REFRESH_MESSAGE: "Lâchez pour actualiser",
        REGISTER: "Créer un compte",
        EMAIL_MESSAGE: "Votre adresse Email",
        PASSWORD_MESSAGE: "Votre mot de passe",
        REMEMBER_ME: "Se souvenir de moi",
        FORGOT_PASSWORD_MESSAGE: "Mot de passe oublié ?",
        DONT_HAVE_ACCOUNT_MESSAGE: "Vous n'avez pas de compte ?",
        USERNAME_PLACEHOLDER: "Votre nom d'utilisateur. ex: sali",
        YOU_ARE: "Vous êtes",
        GRADUATE_ENGINEER: "Ingénieur diplomé",
        TEACHER: "Enseignant",
        YOUR_DEPARTMENT: "Votre département",
        CONTACT_PLACEHOLDER: "Votre numéro de téléphone",
        EMAIL_PLACEHOLDER: "Votre email",
        PASSWORD_PLACEHOLDER: "Votre mot de passe",
        PASSWORD_REPEAT_PLACEHOLDER: "Répétez votre mot de passe",
        LASTNAME: "Nom",
        USERNAME_ALREADY_ASSIGNED: "Le nom d'utilisateur est déjà utilisé",
        EMAIL_ALREADY_ASSIGNED: "L'email est déjà utilisé",
        ALREADY_HAVE_ACCOUNT: "Déjà un compte",
        ACCEPT_TERM_AND_CONDITIONS: "Accepter les termes et conditions de l'Alumni",
        PASSWORDS_MUST_BE_IDENTICAL: "Les mots de passe doivent être identiques",
        CHANGE_PASSWORD: " Changer le mot de passe",
        FORGOT_PASSWORD_EMAIL_SENT: "Si le mail <b>%{email}</b> existe, vous recevrez le lien de changement du mot de passe dans votre messagerie mail.",
        NEW_PASSWORD_PLACEHOLDER: "Votre nouveau mot de passe",
        KEY_EXPIRED_ERROR: "La clé a expiré. Veuillez renouveler votre demande.",
        INVALID_KEY_ERROR: "La lcé n'est pas valide",
        USER_STATUS_PENDING_PASSWORD_ACTIVATION_MESSAGE: "Vous avez demandé à changer le mot de passe. Veuillez cliquer sur le lien que vous avez recu par email.",
        INVALID_RESET_PASSWORD_LINK: "Ce lien a expiré ou n'est plus valide.",
        SEARCH_A_POST: "Rechercher une publication...",
        POSTS: "Publications",
        OFFERS: "Offres",
        PROJECTS: "Projets",
        MEMBERS: "Membres",
        PROFILE: "Profil",
        MORE_PAGES: "Autres pages",
        EMAILS: "Emails",
        EVENTS: "Évènements",
        ACCOUNT: "Compte",
        ANALYTICS: "Métriques",
        LATEST_REGISTERED: "Derniers inscrits",
        SEE_ALL: "Tout afficher",
        TITLE: "Titre",
        COST: "Coût",
        CHANGE_FILES: "Changer les fichiers",
        CHOOSE_FILES: "Choisir les fichiers",
        INVALID_AMOUNT: "Montant invalide",
        UPLOAD: "Télécharger",
        CHANGE_REPORTS: "Changer les rapports",
        CHOOSE_REPORTS: "Choisir les rapports",
        END_DATE_BEFORE_START_DATE: "La date de fin ne peut pas dépasser la date de debut",
        INVALID_DATE: "Date non valide",
        NUMBER_OF_CONTRIBUTION: "%{count} participations",
        NUMBER_OF_CONTRIBUTION_0: "0 participation",
        NUMBER_OF_CONTRIBUTION_1: "1 participation",
        NUMBER_OF_DAYS_LEFT: "Il reste %{count} jours",
        NUMBER_OF_DAYS_LEFT_0: "Il ne reste plus jours",
        NUMBER_OF_DAYS_LEFT_1: "Il  reste 1 jour",
        NUMBER_OF_DAYS_PAST: "Depuis %{count} jours",
        NUMBER_OF_DAYS_PAST_0: "Depuis O jour",
        NUMBER_OF_DAYS_PAST_1: "Depuis 1 jour",
        CONTRIBUTE: "Contribuer",
        PAYMENT_SUCCESSFUL:"Votre contribution a été pris en compte. Nous vous remercions",
        PAYMENT_ERROR: "Votre contribution n'a pas pu etre effectué. Vérifiez votre solde ou votre plafond de paiement",
        AMOUNT_PLACEHOLDER: "Entrer le montant",
        AMOUNT: "Montant",
        OPTIONAL_MESSAGE: "Un petit message ? (optionnel)",
        PROCEEDING_CONTRIBUTION: "Nous procédons à votre contribution ...",
        CONTRIBUTE_BY_BANK_CARD: "Contribuer par carte bancaire",
        FULL_NAME: "Nom complet",
    },
};
export default Translations;
