import {UAParser} from "ua-parser-js";
import * as  arch from "arch";
import React from 'react';

export const inArray = (array, values) => {
    for (const element of values) {
        if (array.indexOf(element) !== -1) {
            return true;
        }
    }
    return false;
}

export const localeFrOptions = {
    firstDayOfWeek: 1,
    dayNames: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
    dayNamesShort: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
    monthNamesShort: ['jan', 'fev', 'mar', 'avr', 'mai', 'jui', 'jui', 'aou', 'sep', 'oct', 'nov', 'dec'],
    today: "Aujourd'hui",
    clear: 'Supprimer'
};

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const isFormFieldInvalid = (props, name, attribute) => {
    if (!!attribute && Object.keys(props.touched).length !== 0 && Object.keys(props.errors).length !== 0) {
        return !!(props.touched[name] && props.errors[name] && props.errors[name][attribute]);
    }
    return !!(props.touched[name] && props.errors[name]);
}

export const getFormErrorMessage = (props, name, attribute) => {
    if (!!attribute && Object.keys(props.touched).length !== 0 && Object.keys(props.errors).length !== 0) {
        return isFormFieldInvalid(props, name, attribute) &&
            <small className="s-invalid-feedback font-xssss">{props.errors[name][attribute]}</small>;
    }
    return isFormFieldInvalid(props, name) && <small className="p-error">{props.errors[name]}</small>;
}

export const getBrowser = () => {
    let browser, cpu, device, engine, os, parser;
    parser = new UAParser();
    browser = parser.getBrowser();
    device = parser.getDevice();
    engine = parser.getEngine();
    os = parser.getOS();
    cpu = parser.getCPU();
    return {
        browserName: browser.name,
        browserVersion: browser.version,
        deviceModel: device.model,
        deviceType: device.type,
        deviceVendor: device.vendor,
        engineName: engine.name,
        engineVersion: engine.version,
        osName: os.name,
        osVersion: os.version,
        cpuArchitecture: !!cpu.architecture ? cpu.architecture : arch(),
        language: navigator.language,
        languages: navigator.languages,
        userAgent: navigator.userAgent,
        referrer: document.referrer || 'N/A',
        online: navigator.onLine,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        screenResolution: window.screen.width + ' x ' + window.screen.height,
        cookieEnabled: navigator.cookieEnabled
    };
}

export const getProfileUrl = (userProfile) => {
    if (userProfile == null) {
        return "/assets/images/user/studentm.svg";
    }

    if (userProfile.account.photo) {
        return userProfile.account.photo.url;
    } else {
        if (userProfile.memberTypeEnum === "STUDENT") {
            if (userProfile.account.genderTypeEnum === "MALE") {
                return "/assets/images/user/studentm.svg";
            } else {
                return "/assets/images/user/studentf.svg";
            }
        } else if (userProfile.memberTypeEnum === "ENGINEER") {
            if (userProfile.account.genderTypeEnum === "MALE") {
                return "/assets/images/user/graduatem.svg";
            } else {
                return "/assets/images/user/graduatef.svg";
            }
        } else if (userProfile.memberTypeEnum === "TEACHER") {
            if (userProfile.account.genderTypeEnum === "MALE") {
                return "/assets/images/user/teacherm.png";
            } else {
                return "/assets/images/user/teacherf.png";
            }
        } else {
            return "/assets/images/user/studentm.svg";
        }
    }
}
