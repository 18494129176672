import React, { Component, Fragment } from "react";
import { I18n } from "react-redux-i18n";
import PhoneInput from "react-phone-input-2";
import DepartmentService from "../services/DepartmentService";
import UserService from "../services/UserService";
import { Redirect } from "react-router-dom";
import { history } from "../helpers/history";

import { Steps } from 'primereact/steps';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
const items = [
    {
        label: 'Personal'
    },
    {
        label: 'Autre'
    },
];


class Register extends Component {

    emptyElement = {
        userName: '',
        firstName: '',
        lastName: '',
        genderTypeEnum: '',
        mobilePhone: { formattedNumber: null },
        email: '',
        departmentId: '',
        password: '',
        password2: '',
        memberTypeEnum: '',
        submitted: false,
        loading: false,
        isRegistered: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            departments: [],
            element: this.emptyElement,
            validPhoneNumber: true,
            validUsername: true,
            validEmail: true,
            activeIndex: 0
        };

        this.departmentService = new DepartmentService();
        this.userService = new UserService();
        this.onInputChange = this.onInputChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleRegister = this.handleRegister.bind(this);
    }

    componentDidMount() {
        this.loadDepartments();
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let element = { ...this.state.element };
        element[`${name}`] = val;
        this.setState({ element });
    }

    loadDepartments() {
        this.departmentService.getAll().then(
            response => {
                this.setState({
                    departments: response.data.content? response.data.content:[]
                });
            }
        );
    }

    handlePhoneChange(value, country) {
        country['formattedNumber'] = value
        let element = { ...this.state.element };
        element['mobilePhone'] = country;
        this.setState({ element: element });
    }

    handleRegister(event) {
        event.preventDefault();
        let state = { submitted: true };
        if (!!this.state.element.mobilePhone.formattedNumber &&
            this.state.element.password === this.state.element.password2
        ) {
            this.setState({
                loading: true,
                validPhoneNumber: true,
                validUsername: true,
                validEmail: true,
            });
            console.log("user..", this.state.element)
            this.userService.createGuest(this.state.element)
                .then(() => {
                    this.setState({
                        isRegistered: true
                    });
                })
                .catch(error => {

                    this.setState({
                        loading: false,
                    });
                    if ("INVALID_PHONE_NUMBER" === error.response.data.code) {
                        this.setState({
                            validPhoneNumber: false,
                        });
                    } else if ("DUPLICATED_USERNAME" === error.response.data.code) {
                        this.setState({
                            validUsername: false,
                        });
                    } else if ("DUPLICATED_EMAIL" === error.response.data.code) {
                        this.setState({
                            validEmail: false,
                        });
                    }
                });
        }
        this.setState(state);
    }


    render() {
        if (this.state.isRegistered) {
            return <Redirect to="/login" />
        }

        return (
            <Fragment>
                <div className="main-wrap">
                    <div className="nav-header bg-transparent shadow-none border-0">
                        <div className="nav-top w-100">
                            <a href="/"><img src='assets/images/logo.png'
                                className="text-success display1-size me-2 ms-0"></img><span
                                    className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">Alumni ENSPM</span>
                            </a>
                            <button className="nav-menu me-0 ms-auto"></button>

                            <a href="/login"
                                className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w200 text-center lh-20 rounded-xl">{I18n.t('LOGIN')}</a>
                            <a href="/register"
                                className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w200 text-center lh-20 rounded-xl">{I18n.t('REGISTER')}</a>
                        </div>
                    </div>

                    <div className="row" style={{ overflow: "scroll" }}>
                        <div className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
                            style={{ backgroundImage: `url('assets/images/login-background.jpg')` }}></div>
                        <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3"
                        >
                            <div className="card shadow-none border-0 ms-auto me-auto login-card">
                                <div className="card-body rounded-0 text-left">
                                    <form className="alumni-register-form"
                                        onSubmit={this.handleRegister}>
                                        <Steps model={items} activeIndex={this.state.activeIndex} readOnly={false} />
                                        <div className="fw-700 ">{I18n.t('REGISTER')}</div>
                                        <div style={this.state.activeIndex == 0 ? { display: 'block' } : { display: 'none' }}>
                                            <div className="form-group icon-input mb-1">
                                                <InputText value={this.state.element.firstName} placeholder={I18n.t('FIRSTNAME')} onChange={(e) => this.onInputChange(e, 'firstName')} style={{ width: '100%' }} />
                                            </div>
                                            <div className="form-group icon-input mb-1">
                                                <InputText value={this.state.element.lastName} placeholder={I18n.t('LASTNAME')} onChange={(e) => this.onInputChange(e, 'lastName')} style={{ width: '100%' }} />
                                            </div>
                                            <div className="form-group mb-1">
                                                <select className="form-control form-control-sm"
                                                    required
                                                    value={this.state.element.genderTypeEnum}
                                                    onChange={(e) => this.onInputChange(e, 'genderTypeEnum')}
                                                >
                                                    <option defaultValue value="MALE">{I18n.t('MALE')}</option>
                                                    <option defaultValue value="FEMALE">{I18n.t('FEMALE')}</option>
                                                </select>
                                            </div>
                                            <div className="form-group mb-1">
                                                <div className="sn-field">
                                                    <PhoneInput
                                                        required
                                                        inputStyle={{ width: '100%' }}
                                                        country={'cm'}
                                                        placeholder={I18n.t('CONTACT_PLACEHOLDER')}
                                                        name="contact"
                                                        value={this.state.element.mobilePhone.formattedNumber}
                                                        onChange={this.handlePhoneChange}
                                                    />
                                                    {this.state.submitted && !this.state.element.mobilePhone.formattedNumber &&
                                                        <small
                                                            className="s-invalid-feedback font-xssss">{I18n.t('FIELD_IS_REQUIRED')}</small>}
                                                    {this.state.submitted && !this.state.validPhoneNumber &&
                                                        <small
                                                            className="s-invalid-feedback font-xssss">{I18n.t('INVALID_PHONE_NUMBER')}</small>}
                                                </div>
                                            </div>
                                            <div className="form-group icon-input mb-1">
                                                <Calendar value="" showIcon={true} style={{ width: '100%' }} placeholder="Date de naissance" ></Calendar>
                                            </div>
                                            <div className="form-group icon-input mb-1">
                                                <InputText value="" placeholder="Lieu de naissancce" style={{ width: '100%' }} />
                                            </div>

                                            <div className="form-group mb-1 ">
                                                <Button label="Suivant" className="p-button-primary" style={{ width: '100%' }} onClick={(e) => this.setState({ activeIndex: 1 })} />
                                            </div>
                                        </div>

                                        <div style={this.state.activeIndex == 1 ? { display: 'block' } : { display: 'none' }}>
                                            <div className="form-group mb-1">
                                                <select
                                                    required
                                                    className="form-control form-control-sm"
                                                    value={this.state.element.memberTypeEnum}
                                                    onChange={(e) => this.onInputChange(e, 'memberTypeEnum')}
                                                >
                                                    <option defaultValue value="">{I18n.t('YOU_ARE')}</option>
                                                    <option value="ENGINEER">{I18n.t('GRADUATE_ENGINEER')}</option>
                                                    <option value="TEACHER">{I18n.t('TEACHER')}</option>
                                                </select>
                                            </div>
                                            <div className="form-group mb-1">
                                                <select className="form-control form-control-sm"
                                                    required
                                                    value={this.state.element.departmentId}
                                                    onChange={(e) => this.onInputChange(e, 'departmentId')}
                                                >
                                                    <option defaultValue value="">{I18n.t('YOUR_DEPARTMENT')}</option>
                                                    {this.state.departments.map((e, key) => {
                                                        return <option key={key}
                                                            value={e.id}>{e.name} - {e.acronym} </option>;
                                                    })}
                                                </select>
                                            </div>
                                            <div className="form-group icon-input mb-1">
                                                <input type="text"
                                                    name="matricule"
                                                    required
                                                    className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                                    placeholder="Matricule"
                                                    value=""
                                                />
                                            </div>
                                            <div className="form-group icon-input mb-1">
                                                <input type="text"
                                                    name="promotion"
                                                    required
                                                    className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                                    placeholder="Promotion"
                                                    value=""
                                                />
                                            </div>

                                            <div className="form-group icon-input mb-1">
                                                <i className="font-sm ti-email text-grey-500 pe-0"></i>
                                                <input type="Email"
                                                    name="email"
                                                    required
                                                    className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                                    placeholder={I18n.t('EMAIL_PLACEHOLDER')}
                                                    value={this.state.element.email}
                                                    onChange={(e) => this.onInputChange(e, 'email')}
                                                />
                                                {this.state.submitted && !this.state.validEmail &&
                                                    <small
                                                        className="s-invalid-feedback font-xssss">{I18n.t('EMAIL_ALREADY_ASSIGNED')}</small>}
                                            </div>
                                            <div className="form-group icon-input mb-1">
                                                <input type="Password"
                                                    required
                                                    className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                                                    placeholder={I18n.t('PASSWORD_PLACEHOLDER')}
                                                    value={this.state.element.password}
                                                    onChange={(e) => this.onInputChange(e, 'password')}
                                                />
                                                <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                            </div>
                                            <div className="form-check text-left mb-1">
                                                <input required type="checkbox" className="form-check-input mt-2"
                                                    id="exampleCheck2" />
                                                <label className="form-check-label font-xsss text-grey-500">
                                                    {I18n.t('ACCEPT_TERM_AND_CONDITIONS')}
                                                </label>

                                            </div>

                                            <button
                                                type="submit"
                                                className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 "
                                                style={{ height: '45px' }}>
                                                {this.state.loading &&
                                                    <span className="spinner-border spinner-border-sm "
                                                        style={{ marginRight: '5px' }} aria-hidden="true"></span>}
                                                {I18n.t('REGISTER')}
                                            </button>
                                        </div>




                                    </form>

                                    <div className="col-sm-12 p-0 text-left">
                                        <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                                            {I18n.t('ALREADY_HAVE_ACCOUNT')}
                                            <a href="/login" className="fw-700 ms-1">{I18n.t('LOGIN')}</a>
                                        </h6>
                                        <h6 className="text-grey-800 font-xsss fw-500 mt-0 mb-0 lh-32">
                                            Vous avez des questions ? écrivez nous à
                                        </h6>
                                        <h6 className="text-grey-800 font-xsss fw-500 mt-0 mb-0 lh-32">
                                            <a href="mailto:contact.alumni@enspm.univ-maroua.cm">contact.alumni@enspm.univ-maroua.cm</a>
                                        </h6>
                                        <h6 className="text-grey-800 font-xsss fw-500 mt-0 mb-0 lh-32">
                                            ou dans <a href="https://t.me/+_uswUqQ7ue43NDI8">ce groupe telegram</a>
                                        </h6>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Register;
